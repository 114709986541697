<template>
	<content-page>
		<template v-slot:content>
	<v-sheet flat>
		<h2 class="mb-2 pl-5">
			Frequently Asked Questions
		</h2>

		<content-contents :value="ids">
			<template v-slot:prepend>
				<p>
					A selection of frequently asked questions is provided on this
					page. Need more help? Get in touch with
					<a router-link to="/contact">support!</a>
				</p>
			</template>
		</content-contents>
		<content-list :value="ids"></content-list>
	</v-sheet>
		</template>
	</content-page>
</template>

<script>
import ContentList from "@c/ui/ContentList";
import ContentContents from "@c/ui/ContentContents";
import ContentPage from "@c/ui/ContentPage";
export default {
	name: "Faq",
	components: {
		ContentList,
		ContentContents,
		ContentPage
	},
	data: () => {
		return {
			ids: [
				"faq1",
				"faq2",
				"faq3",
				"faq4",
				"faq5",
				"faq6",
				"faq7",
				"faq8",
				"faq9",
				"faq10",
				"faq11",
				"faq12",
				"faq13",
				"faq14",
				"faq15",
				"faq16",
				"faq17",
				"faq18",
				"faq19",
				"faq20",
				"faq21",
				"faq22",
				"faq23",
				"faq24",
				"faq25",
				"faq26"
			],
		};
	},
};
</script>
